import Logger from './Logger'
import Services from "../../services/Services";
class DocEngine {
    constructor() {
        this.nodeURL = Services.getConfig().node_URL;
    }

    runTranscriber(rest, data, hash, modelId) {
        Logger.log(-1, "DocEngine.run()");
        return this.transcribeAudio(rest, data, hash, modelId);
    }

    async transcribeAudio(rest, data, hash, appID) {
        try {
            const fileDataBinding = rest.input?.fileDataBinding;
            if (fileDataBinding) {
                const configData  = {
                    audio: data[fileDataBinding],
                    ...rest
                }
           
                const formData = await this.buildFormData(configData);
                const header = await this.createDefaultHeader(configData, hash, appID)
           
                const response = await fetch(`${Services.getConfig().node_URL}` + '/transcribe-audio', {
                    method: "POST",
                    mode: 'cors',
                    headers: header,
                    cache: 'no-cache',
                    redirect: 'follow',
                    referrer: 'no-referrer',
                    body: formData
                });
        
                const result = await response.json();
                return result.text;
            }
            return "";
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    runParser(config, data, hash, appID) {
        Logger.log(-1, "DocEngine.run()", hash, appID);
        return this.parseDocument(config, data, hash, appID);
    }
    async parseDocument(config, data, hash, appID) {
        Logger.log(1, "DocEngine.parseDocument()", "enter >");
        return new Promise(async (resolve, reject) => {
            const fileDataBinding = config.input?.fileDataBinding;
            const configData  = {
                file: data[fileDataBinding],
                ...config
            }
            const formData = await this.buildFormData(configData)
            const header = await this.createDefaultHeader(configData, hash, appID, data)
            fetch(`${Services.getConfig().node_URL}` + '/doc-to-text', {
                method: "POST",
                mode: 'cors',
                cache: 'no-cache',
                headers: header,
                redirect: 'follow',
                referrer: 'no-referrer',
                body: formData
            })
            .then(response => {
                response.json()
            .then(data => resolve(data.text))
            }).catch(e => {
                reject(e)
            });
        });
    }

    runTextToDoc(configData, data, hash, appID) {
        Logger.log(-1, "DocEngine.run()", hash, appID);
        return this.parseTextToDoc(configData, data, hash, appID);
    }

    async parseTextToDoc(config, data, hash, appID) {
        Logger.log(1, "DocEngine.parseDocument()", "enter >");
        return new Promise(async (resolve, reject) => {            
            const databinding = config.input?.databinding;
            const configData  = {
                valuestr: data[databinding],
                ...config
            }
            const formData = await this.buildFormData(configData)
            const header = await this.createDefaultHeader(configData, hash, appID, data)
            fetch(`${Services.getConfig().node_URL}` + '/text-to-doc', {
                method: "POST",
                mode: 'cors',
                cache: 'no-cache',
                headers: header,
                redirect: 'follow',
                referrer: 'no-referrer',
                body: formData
            })
            .then(response => response.blob())
            .then(blob => {   
                const blobUrl = window.URL.createObjectURL(blob);
                const format = configData.output.format
                let urlWithDocxExtension;
                if (format === 'docx') {
                    urlWithDocxExtension = `${blobUrl}#output.docx`; 
                } if (format === 'csv') {
                    urlWithDocxExtension = `${blobUrl}#output.csv`; 
                } else if (format === 'excel') {
                    urlWithDocxExtension = `${blobUrl}#output.xlsx`; 
                } else if (format === 'pdf') {
                    urlWithDocxExtension = `${blobUrl}#output.pdf`; 
                }               
                resolve(urlWithDocxExtension);
            })
            .catch(e => {
                reject(e)
            });
        });
    }
    async buildFormData(configData) {
        const formData = new FormData();
        if (configData.input.type === 'file') {
            const file = configData.file;
            formData.append("doc", file);
            Logger.log(-1, "DocEngine.buildFormData()", "file added", formData);
        } else if (configData.input.type === 'url') {
            formData.append("url", configData.input.url);
            Logger.log(-1, "DocEngine.buildFormData()", "url added", formData);
        } else if (configData.input.type === 'AUDIO') {
            const audioFile = configData.audio;
            const audioBlob = await this.getBlobFromUrl(audioFile);            
            formData.append("audio", audioBlob, "recording.wav");
            formData.append("data", configData);
            Logger.log(-1, "DocEngine.buildFormData()", "url added", formData);
        } else if (configData.input.type === 'markdown') {
            const content = typeof configData.valuestr === 'object' ? JSON.stringify(configData.valuestr) : configData.valuestr;
            formData.append("doc", content);
            formData.append("format", configData.output.format);
            Logger.log(-1, "DocEngine.buildFormData()", "value added", formData);
        } 
        return formData;
    }
    async getBlobFromUrl(audioUrl) {
        try {
            const response = await fetch(audioUrl);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const audioBlob = await response.blob();
            return audioBlob;
        } catch (error) {
            console.error('Failed to fetch the audio blob:', error);
            throw error;
        }
    }
    handleOutput(resolve, reject, response) {
        Logger.log(2, "DocEngine.handleOutput()", "enter", response);
        if (response.ok) {
            response.json().then(data => {
                Logger.log(2, "DocEngine.handleOutput()", "exit", data);
                resolve(data);
            });
            return;
        }
        reject(new Error(`Could not process the document: ${response.statusText}`));
    }
    async createDefaultHeader(request, hash, appID, values) {
        let headers = {}
        let token = await this.buildToken(request, values)
        headers['x-flowrabbit-hash'] = hash
        headers['x-flowrabbit-appid'] = appID
        if (request && request.input && request.input.type === 'url') {
            return { 'Content-Type': 'application/json', 'Accept': 'application/json' };
        }

        if (token) {
            headers['Authorization'] = `${token}`.trim() // ${secret.audio}}
        }
        return headers
    }

    async buildToken(request, values) {
        if (request.token) {
            let data = await this.fillString(request.token, values, true);
            Logger.log(1, "RestEngine.buildToken()", "exit", data)
            return data;
        }
        return;
    }

    async fillString(s, values) {
        for (let key in values) {
            let value = this.getValueByKey(values, key)
            let pattern = "${" + key + "}"
            s = this.replacePattern(s, pattern, value)
        }
        if (s.indexOf('${') >= 0) {
            Logger.log(-1, "RestEngine.fillString()", "> Not all parameters replaced!" + s)
        }
        return s
    }

    replacePattern(s, pattern, value) {
        let i = 0
        while (s.indexOf(pattern) >= 0 && i < 100) {
            s = s.replace(pattern, value)
            i++
        }
        return s
    }

    getValueByKey(values, key) {
        /**
         * Shouldn't this be JSONPath?
         */
        return values[key]
    }

    getAuthType(request) {
        if (request.authType === 'Bearer') {
            return 'Bearer'
        }
        if (request.authType === 'Basic') {
            return 'Basic'
        }
        return ''
    }
}
export default new DocEngine();