<template>
  <div class="VommondContentContainer">
    <div class="MatcContent MatcAbout">
      <div class="MatcSection">
        <div class="container container-center-xxl" v-if="user">
          <div class="MatcCard">
            <div class="MatcCardHeader">
              <ul class="MatcTabs">
                <li
                  :class="{ MatcTabActive: activeTab === 'profile' }"
                  @click="activeTab = 'profile'"
                >
                  <a>Profile</a>
                </li>
                <li
                  :class="{ MatcTabActive: activeTab === 'credits' }"
                  @click="activeTab = 'credits'"
                >
                  <a>Billing</a>
                </li>
              </ul>
            </div>
            <div class="MatcCardContent">
              <div v-if="activeTab === 'profile'">
                <div v-if="isCustomAuth">
                  <div class="MatcCard">
                    <div class="MatcCardHeader">
                      <p>Change Profile Details</p>
                    </div>
                    <div class="MatcCardContent">
                      <div
                        v-if="accounterror && accounterror.length > 0"
                        class="MatcAlertBox MatcMarginBottom MatcAlertBoxDanger"
                      >
                        <span class="MatcAlertBoxContent">{{
                          accounterror
                        }}</span>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <!-- User Image Placeholder -->
                        </div>
                        <div class="col-md-8">
                          <div class="luisa-form-row">
                            <label class="MatcLabel">First Name</label>
                            <div class="control">
                              <input
                                class="form-control input-lg"
                                v-model="user.name"
                                placeholder="Enter your first name"
                                required
                              />
                            </div>
                          </div>

                          <div class="luisa-form-row">
                            <label class="MatcLabel">Last Name</label>
                            <div class="control">
                              <input
                                class="input-lg form-control is-normal"
                                v-model="user.lastname"
                                placeholder="Enter your last name"
                                required
                              />
                            </div>
                          </div>

                          <div class="luisa-form-row">
                            <label class="luisa-checkbox">
                              <input
                                type="checkbox"
                                :value="user.newsletter"
                                v-model="user.newsletter"
                                :aria-label="label"
                              />
                              <span class="checkbox-custom"></span>
                              <span class="checkbox-label"
                                >I want to receive the newsletter</span
                              >
                            </label>
                          </div>

                          <div class="MatcButtonBar">
                            <button
                              class="MatcButton MatcButtonPrimary"
                              @click="save"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="MatcCard MatcMarginTop">
                    <div class="MatcCardHeader">
                      <p>Change Password</p>
                    </div>
                    <div class="MatcCardContent">
                      <div
                        v-if="passworderror && passworderror.length > 0"
                        class="MatcAlertBox MatcMarginBottom MatcAlertBoxDanger"
                      >
                        <span class="MatcAlertBoxContent">{{
                          passworderror
                        }}</span>
                      </div>
                      <div class="luisa-form-row">
                        <label class="MatcLabel">New Password</label>
                        <input
                          type="password"
                          class="input-lg form-control is-normal"
                          v-model="password"
                          placeholder="Enter new password"
                        />
                      </div>
                      <div class="luisa-form-row">
                        <label class="MatcLabel">Confirm Password</label>
                        <input
                          type="password"
                          class="input-lg form-control is-normal"
                          v-model="doublepassword"
                          placeholder="Confirm new password"
                        />
                      </div>

                      <div class="MatcButtonBar">
                        <button
                          class="MatcButton MatcButtonPrimary"
                          @click="savePassword"
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="MatcCard MatcMarginTop">
                    <div class="MatcCardHeader">
                      <p>Delete Account</p>
                    </div>
                    <div class="MatcCardContent">
                      <div class="MatcAlertBox MatcBetween MatcAlertBoxWarning">
                        <span class="MatcAlertBoxContent">
                          Warning: Deleting your account will result in data
                          loss!
                        </span>
                        <button
                          class="MatcLinkButton MatcLinkWarningButton"
                          @click="retire"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <p class="MatcLead MatcMarginBottomXL">
                    Your credentials are managed by Keycloak. Please contact
                    your administrator for assistance.
                  </p>
                </div>
              </div>
              <div v-if="activeTab === 'credits'">
                <AccountBilling :user="user" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Services from "../services/Services";
import AccountBilling from "./AccountBilling.vue";

export default {
  name: "Account",
  data() {
    return {
      password: "",
      doublepassword: "",
      passworderror: "",
      accounterror: "",
      creditError: "",
      user: null,
      activeTab: "profile",
      organizations: [],
      credits: 0,
      creditAmount: 0,
      email: "",
    };
  },
  components: {
    AccountBilling,
  },
  computed: {
    isCustomAuth() {
      return Services.getConfig().auth !== "keycloak";
    }
  },
  methods: {
    async retire() {
      if (confirm("Are you sure you want to delete your account?")) {
        try {
          await Services.getUserService().retire(this.user);
          this.$root.$emit("logout");
        } catch (error) {
          console.error("Error deleting the account", error);
        }
      }
    },
    async save() {
      if (!this.user.name || !this.user.lastname) {
        this.accounterror = "All fields are required.";
        return;
      }
      try {
        const result = await Services.getUserService().save(this.user._id, {
          name: this.user.name,
          lastname: this.user.lastname,
          homepage: this.user.homepage,
          newsletter: this.user.newsletter,
        });
        this.$root.$emit("user", result);
        alert("Account updated successfully.");
      } catch (error) {
        console.error("Error saving account", error);
      }
    },
    async savePassword() {
      if (this.password !== this.doublepassword) {
        this.passworderror = "Passwords do not match.";
        return;
      }
      if (this.password.length < 6) {
        this.passworderror = "Password must be at least 6 characters long.";
        return;
      }
      try {
        const result = await Services.getUserService().save(this.user._id, {
          password: this.password,
        });
        this.$root.$emit("user", result);
        alert("Password updated successfully.");
      } catch (error) {
        console.error("Error updating password", error);
      }
    },
  },
  async mounted() {
    try {
      const user = await Services.getUserService().load();
      this.user = await Services.getUserService().loadById(user.id);
      this.email = this.user.email;
    } catch (error) {
      console.error("Error loading user data", error);
    }
  },
};
</script>

<style scoped>
@import "../scss/form.scss";
@import "../scss/account-settings.scss";
</style>
