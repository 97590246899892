<template>
  <div class="card-container">
    <div class="card-header">
      <p class="card-title">{{ title }}</p>
    </div>
    <div class="card-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
  },
};
</script>
<style scoped>
.card-container {
  background-color: #ffffff;
  border: 1px solid #e5e7eb; /* Equivalent to border-gray-200 */
  border-radius: 12px; /* Equivalent to rounded-xl */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Equivalent to shadow-sm */
  overflow: hidden;
}

.card-header {
  padding: 24px; /* Equivalent to p-6 */
  border-bottom: 1px solid #e5e7eb; /* border-b border-gray-200 */
}

.card-title {
  font-size: 18px; /* Equivalent to text-xl */
  font-weight: 600; /* Equivalent to font-semibold */
  color: #1f2937; /* Equivalent to text-gray-800 */
  margin: 0; /* Reset default margin */
}

.card-content {
  padding: 12px 24px 24px 24px; /* Equivalent to p-6 */
}
</style>
