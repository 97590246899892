<template>
  <div class="MatcMainMenu">
    <div class="MatcMainMenuSection">
      <a class="MatcMainMenuItem" href="/#/">
        <img src="/img/logo-long-w-s.png" width="120" />
      </a>
    </div>
    <div class="MatcMainMenuCenter">
      <div class="MatcCenter"></div>
    </div>
    <div class="MatcMainMenuSection">
      <div
        v-if="isUserLogged"
        class="dropdown"
        :class="{ 'is-active': isAccountDropdownActive }"
      >
        <div class="dropdown-trigger">
          <div id="avatarDropdown" class="MatcMainMenuItem">
            <button
              @click="toggleAccountDropdown"
              style="background: none; border: none"
            >
              <img
                v-if="userImage"
                :src="userImage"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                class="AvatarDropdown"
              />
            </button>
            <transition name="fade">
              <div
                v-if="isAccountDropdownActive"
                class="AvatarDropdownMenu dropdown-menu"
                id="dropdown-menu"
                role="menu"
              >
                <div class="dropdown-content" ref="accountdropdown">
                  <div class="dropdown-profile">
                    <img
                      :src="userImage"
                      alt="User Avatar"
                      class="profile-avatar"
                    />
                    <div class="profile-info">
                      <p class="profile-name">
                        {{ user.name }} {{ user.surname }}
                      </p>
                      <p class="profile-email">{{ user.email }}</p>
                    </div>
                  </div>
                  <hr class="dropdown-divider" />
                  <a
                    class="dropdown-item"
                    @click="navigate('/settings.html')"
                  >
                    <i class="fas fa-user"></i> My Account
                  </a>
                  <hr class="dropdown-divider" />
                  <a class="dropdown-item" @click="logout">
                    <i class="fas fa-sign-out-alt"></i> Logout
                  </a>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div v-else class="MatcMainMenuItem">
        <a @click="goToLogin" class="login-link">Login</a>
      </div>
    </div>
  </div>
</template>

<script>
import Services from "../services/Services";
import { mapActions, mapState } from "vuex";
export default {
  name: "MarketPlaceHeader",
  data() {
    return {
      isDropdownActive: false,
      hasLanguage: false,
      isAccountDropdownActive: false,
      userImage: "",
    };
  },
  props: [],
  components: {},
  computed: {
    ...mapState(["user"]),
    userImageSrc() {
      return this.userImage;
    },
    isUserLogged() {
      return this.user && this.user.id && this.user.id !== -1
    },
  },
  methods: {
    ...mapActions(["loadUser"]),
    goToLogin() {
      this.$router.push("/login.html")
    },
    toggleAccountDropdown() {
      this.isAccountDropdownActive = !this.isAccountDropdownActive;
    },
    navigate(url) {
      this.$router.push(url);
      this.isAccountDropdownActive = false;
    },
    logout() {
      Services.getUserService().logout();
      this.$emit("logout", Services.getUserService().GUEST);
      this.$router.push("/login.html");
    },
    closeAccountDropdown(event) {
      if (
        this.$refs.accountdropdown &&
        !this.$refs.accountdropdown.contains(event.target)
      ) {
        this.isAccountDropdownActive = false;
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isDropdownActive = false;
        this.isAccountDropdownActive = false;
      }
    },
  },
  async mounted() {
    await this.loadUser()
    if (this.user && this.user.id) {
      this.userImage =
        this.user && this.user.image
          ? `rest/user/${this.user.id}/images/${this.user.name}_${this.user.lastname}/${this.user.image}`
          : "";
    }
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style>
@import url("../scss/menu.scss");
</style>
