import AbstractService from "./AbstractService";
import Services from "./Services"

export class StripeService extends AbstractService {
  constructor() {
    super();
  }

  async createPaymentIntent(user, amount) {
    try {
      const response = await fetch(Services.getConfig().proxy_URL + "/create-payment-intent", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        redirect: "follow",
        referrer: "no-referrer",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.token
        },
        body: JSON.stringify({
          amount: amount,
          email: user.email,
          userId: user.id,
        }),
      });
      const result = await response.json();
      return result;
    } catch (e) {
      console.error("Error during transcription:", e);
      throw e;
    }
  }
  async getCustomerDetails(userId) {
    try {
      const response = await fetch(Services.getConfig().proxy_URL + "/customer-details", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        redirect: "follow",
        referrer: "no-referrer",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.token
        },
        body: JSON.stringify({
          userId,
        }),
      });

      console.log("Response status:", response.status);
      const result = await response.json();
      return result;
    } catch (e) {
      console.error("Error during transcription:", e);
      throw e;
    }
  }
  async updateBillingAddress(userId, addressData) {
    try {
      const response = await fetch(Services.getConfig().proxy_URL + "/update-customer-address", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        redirect: "follow",
        referrer: "no-referrer",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.token
        },
        body: JSON.stringify({
          userId,
          addressData,
        }),
      });

      console.log("Response status:", response.status);
      const result = await response.json();
      return result;
    } catch (e) {
      console.error("Error during transcription:", e);
      throw e;
    }
  }
  
  async getStripeSession(userId, email) {
    try {
      const response = await fetch(Services.getConfig().proxy_URL + "/session", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        redirect: "follow",
        referrer: "no-referrer",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.token
        },
        body: JSON.stringify({
          userId,
          email
        }),
      });

      console.log("Response status:", response.status);
      const result = await response.json();
      return result;
    } catch (e) {
      console.error("Error during transcription:", e);
      throw e;
    }
  }
  getCurrentBudget() {
    return this._get("/rest/store/budget/current.json");
  }
}

export default new StripeService();
