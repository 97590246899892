const speechToText = {
  id: "speechToText",
  title: "Speech to Text",
  logo: "ai-speech-text",
  brands: [{ id: "deepgram", label: "Deepgram", logo: "ai-deepgram" }],
  models: [
    {
      id: "deepgram-nova-2",
      name: "Deepgram Nova 2",
      url: "https://openrouter.ai/api/v1/chat/completions",
      type: "deepgram",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          options: [
            "general",
            "meeting",
            "phonecall",
            "voicemail",
            "finance",
            "conversationalai",
            "medical",
          ],
          default: "general",
          type: "DropDown",
          label: "Model",
          id: "model",
          helper: "Choose the model adapted to specific needs",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => {
        return {
          version:
            "4d50797290df275329f202e48c76360b3f22b08d28c196cbc54600319435f8d2",
          input: {
            ...vars,
          },
        };
      },
      input: {
        type: "AUDIO",
      },
      output: {
        path: "id",
        type: "JSON",
      },
    },
  ],
};

export default speechToText;
