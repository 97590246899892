<template>
  <div class="filters-wrapper">
    <div class="filters-content">
      <div class="filter-group">
        <input
          type="text"
          v-model="localSearchQuery"
          class="filter-input"
          placeholder="Search apps..."
          aria-label="Search apps"
        />
      </div>
      <div class="filter-group">
        <div class="custom-multiselect">
          <div
            class="multiselect-display"
            @click="toggleDropdown"
            :class="{ open: dropdownOpen }"
          >
            <span v-if="localSelectedTypes.length">
              {{ localSelectedTypes.map(getTypeLabel).join(", ") }}
            </span>
            <span v-else class="placeholder">Filter by Type</span>
            <svg
              class="dropdown-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                fill="currentColor"
                d="M5.516 7.548a.5.5 0 0 1 .707-.707L10 10.618l3.777-3.777a.5.5 0 0 1 .707.707l-4.03 4.03a.5.5 0 0 1-.707 0l-4.23-4.23z"
              />
            </svg>
          </div>
          <div v-if="dropdownOpen" class="multiselect-options">
            <label
              v-for="option in typeOptions"
              :key="option.value"
              class="multiselect-option"
            >
              <input
                type="checkbox"
                :value="option.value"
                v-model="localSelectedTypes"
              />
              {{ option.label }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["searchQuery", "selectedTypes"],
  emits: ["update:searchQuery", "update:selectedTypes"],
  data() {
    return {
      dropdownOpen: false,
    };
  },
  computed: {
    localSearchQuery: {
      get() {
        return this.searchQuery;
      },
      set(value) {
        this.$emit("update:searchQuery", value);
      },
    },
    localSelectedTypes: {
      get() {
        return this.selectedTypes;
      },
      set(value) {
        this.$emit("update:selectedTypes", value);
      },
    },
    typeOptions() {
      return [
        { value: "image_generation", label: "Image Generation" },
        { value: "agent", label: "Agent" },
        { value: "text_to_speech", label: "Text to Speech" },
        { value: "transcription", label: "Transcription" },
        { value: "multimodal", label: "Multimodal" },
        { value: "chat", label: "Chat" },
      ];
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    getTypeLabel(value) {
      const option = this.typeOptions.find((opt) => opt.value === value);
      return option ? option.label : value;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target) && this.dropdownOpen) {
        this.dropdownOpen = false;
      }
    },
  },
};
</script>

<style scoped>
.filters-wrapper {
  padding: 11.5px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #eff0f1;
}

.filters-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.filter-group {
  flex: 1 1 200px;
  position: relative;
  display: flex;
}

.filter-input {
  width: 100%;
  padding: 10px 14px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  background-color: #fff;
  transition: border-color 0.2s;
}

.filter-input:focus {
  border-color: #80bdff;
  outline: none;
}

.custom-multiselect {
  position: relative;
  width: 100%;
}

.multiselect-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 14px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
}

.multiselect-display.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.placeholder {
  color: #6c757d;
}

.dropdown-icon {
  width: 16px;
  height: 16px;
}

.multiselect-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ced4da;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #fff;
}

.multiselect-option {
  display: flex;
  align-items: center;
  padding: 8px 14px;
  font-size: 14px;
  cursor: pointer;
}

.multiselect-option:hover {
  background-color: #f1f1f1;
}

.multiselect-option input {
  margin-right: 8px;
}

@media (max-width: 768px) {
  .filter-group {
    flex: 1 1 100%;
  }
}
</style>
